<template>
  <div>
    <v-btn
      :to="{ name: 'workBreakCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
      >{{ $t("MENU.ADD") }} {{ $t("BUTTON.NEW") }}
      {{ $t("COMPONENTS.WORK_BREAK") }}</v-btn
    >

    <v-container>
      <v-row no-gutters>
        <v-col cols="6">
          <!-- <v-checkbox v-model="tableOptions.showSearch"
                      label="Търси по колона"
                      class="shrink"></v-checkbox>  -->
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <v-col
          v-for="column of headers.columns"
          :key="column.name"
          :cols="column.cols"
        >
          <span @click="changeOrder(column.name)">
            {{ $t("LABELS." + column.name) }}
            <span v-if="column.name == headers.order">{{
              orderIcon
            }}</span></span
          >
        </v-col>

        <v-col cols="2">
          Операции
        </v-col>
      </v-row>

      <div v-show="!isLoadingWorkBreaks">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <v-col
            class="t-col"
            v-for="col of headers.columns"
            :key="col.name"
            :cols="col.cols"
          >
            {{ item[col.name] }}
          </v-col>
          <v-col
            cols="2"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <EditButton :id="item.id" action-name="workBreakCreateUpdate" />
            <LogLink
              :id="item.id"
              type="work-breaks"
              class-name="ml-8 float-right"
            />
            <DeleteButton
              :id="item.id"
              :delete-action="deleteAction"
              :fetch-data="fetchData"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingWorkBreaks"
      ></v-progress-linear>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_WORK_BREAKS, DELETE_WORK_BREAK } from "@/store/workBreaks.module";

import { mapGetters } from "vuex";
import EditButton from "@/components/ActionButtons/EditButton";
import DeleteButton from "@/components/ActionButtons/DeleteButton";
import LogLink from "@/components/Log/LogLink";

export default {
  name: "WorkBreakList",

  components: { LogLink, DeleteButton, EditButton },

  data() {
    return {
      action: "work_breaks",
      deleteAction: DELETE_WORK_BREAK,
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      items: [],
      totalItems: 0,
      totalPages: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "startTime",
            cols: ""
          },
          {
            name: "endTime",
            cols: ""
          }
        ],
        search: "",
        rows: 100,
        page: 1,
        order: "id",
        orderType: "asc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingWorkBreaks"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    }
  },
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Продукти" }]);

    vm.fetchData();
  },
  methods: {
    fetchData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(GET_WORK_BREAKS, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "workBreakList" });
          }
        });
    }, 500)
  },
  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
